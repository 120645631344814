<template>
  <div>
<!--    <Chart type="doughnut" :data="chartData" :options="lightOptions" />-->
  123
  </div>
</template>

<!--<script>-->
<!--import { ref } from 'vue';-->

<!--export default {-->
<!--  setup() {-->
<!--    const chartData = ref({-->
<!--      labels: ['A','B','C'],-->
<!--      datasets: [-->
<!--        {-->
<!--          data: [300, 50, 100],-->
<!--          backgroundColor: ["#FF6384","#36A2EB","#FFCE56"],-->
<!--          hoverBackgroundColor: ["#FF6384","#36A2EB","#FFCE56"]-->
<!--        }-->
<!--      ]-->
<!--    });-->

<!--    const lightOptions = ref({-->
<!--      plugins: {-->
<!--        legend: {-->
<!--          labels: {-->
<!--            color: '#495057'-->
<!--          }-->
<!--        }-->
<!--      }-->
<!--    });-->

<!--    return { chartData, lightOptions }-->
<!--  }-->
<!--}-->
<!--</script>-->